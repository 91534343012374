export const observeInViewportOnce = (
	el: Element,
	callback: () => void,
	threshold = 0.5
) => {
	const observer = new IntersectionObserver(([entry]) => {
		if (entry.isIntersecting) {
			callback();
			observer.disconnect();
		}
	}, { threshold });

	observer.observe(el);
	return observer;
};