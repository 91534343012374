import { getPlacementList, placementHasIntermingle } from '@schibsted-nmp/advertising-companion';
import { DisplayAdsPlacement, RecommendationItem, Recommendations } from '../../../server/types.js';

export const isItemCMS = (item: RecommendationItem) => {
	return item?.type === 'CMS';
};

export const isItemAdvertising = (item: RecommendationItem) => {
	return (
		item?.type === 'NATIVE' || item?.type === 'BANNER' || item?.type === 'oikotie' || item?.type === 'oikotie-section'
	);
};

export const getDisplayAdsPlacement = () => getPlacementList().filter(placementHasIntermingle);

export const insertDisplayAds = (displayAdsPlacement: DisplayAdsPlacement[], data: Recommendations) => {
	if (displayAdsPlacement) {
		displayAdsPlacement.forEach((ad: DisplayAdsPlacement) => {
			if (data?.items?.find((r) => r.itemId === ad.placementId)) return;

			if (data?.items?.length < ad.intermingle.grid) return;

			const gridPosition = ad.intermingle.grid - 1;

			const part1 = data?.items?.slice(0, gridPosition);
			const part2 = data?.items?.slice(gridPosition);
			const advt = { itemId: ad.placementId, type: 'BANNER' };
			data.items = [...part1, advt, ...part2];
		});
	}
};
