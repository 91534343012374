export type ScrollEvaluation = {
	shouldFetchMore: boolean;
	shouldRemoveScroll: boolean;
};

export const shouldTriggerInfiniteScroll = (
	scrollPosition: number,
	containerHeight: number,
	canFetchMore: boolean,
	nextPage: number,
	halfwayIndex: number,
): ScrollEvaluation => {
	const endOfPage = scrollPosition >= containerHeight;
	return {
		shouldFetchMore: endOfPage,
		shouldRemoveScroll: nextPage === halfwayIndex || !canFetchMore,
	};
};
