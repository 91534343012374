import { PulseEvent, RecommendationItem, Recommendations } from '../../../server/types.js';
import pulse from '@finn-no/pulse-sdk';
import { observeInViewportOnce } from './dom-utils.js';

export const sendPulseEvent = (
	event: PulseEvent,
	options: Record<string, unknown> = {}
)=> {
	pulse.trackEvent(
		event,
		{
			disableAddClassifiedAdToItems: true,
			...options,
		}
	);
}

export const trackViewEvent = (
	data: Recommendations,
	getElementForItem: (item: RecommendationItem) => Element | null
) => {
	data?.items?.forEach((item: RecommendationItem) => {
		const element = getElementForItem(item);

		if (element && !item.hasBeenViewed && item.tracking?.pulse) {
			observeInViewportOnce(element, () => {
				if (item.hasBeenViewed) return;
				item.hasBeenViewed = true;

				const trackingData = item.tracking?.pulse?.recommendation?.find(
					(event) => event.type === 'View'
				);

				if (trackingData) {
					sendPulseEvent({
						...trackingData,
						recommendation: data?.['tracking-context']?.recommendation,
					})
				}
			});
		}
	});
};

export const trackClickEvent = (item: RecommendationItem, data: Recommendations) => {
	if (item?.tracking?.pulse) {
		const trackingData: PulseEvent = item.tracking.pulse.recommendation.filter((event) => event.type === 'Click')[0];

		if (trackingData) {
			sendPulseEvent({
				...trackingData,
				recommendation: data?.['tracking-context']?.recommendation,
			})
		}
	}
};

export const trackOikotieClick = ( elementType: string, position?: string ) => {
	sendPulseEvent({
		type: 'Click',
		name: 'Oikotie teaser',
		intent: 'Open',
		object: {
			type: 'UIElement',
			elementType,
			...(position ? { position } : {}),
		},
	});
};
